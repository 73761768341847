.hidden {
    display: none !important;
}

details:first-of-type {
    border-top: solid #ccc 1px;
}
details,
.auk__accordion {
    display: block;
    margin: 0;
    border-bottom: solid #ccc 1px;
    font-size: 0.8em;
}
.auk__accordion-body {
    display: flex;
    padding: 5px;
}
summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    padding: 5px;
    cursor: pointer;
    ::-webkit-details-marker {
        display: none;
    }
    &:hover {
        background: #f1f1f1;
    }
    &:after {
        //  font-family: "Material Icons";
        content: '...';
        color: #111;
        float: right;
        font-size: 1em;
        font-weight: bold;
        margin: -20px 0 0 0;
        padding: 0;
        text-align: center;
        width: 20px;
    }
}

details[open] summary::-webkit-details-marker {
    color: #000;
    background: #fff;
}

details[open] summary:after {
    // font-family: "Material Icons";
    content: '\00d7';
    color: #111;
}

#Selected-Skill,
table {
    width: 100%;
    border-collapse: collapse;
    thead td {
        border: none;
    }
    tbody,
    td {
        text-align: center;
        border: 1px solid rgb(40 130 130);
        padding: 5px;
    }
}

.skill-drag-item {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
    cursor: all-scroll !important;
}
.cursor-pointer {
    cursor: pointer !important;
}

.skill-drag-text {
    width: 100%;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ml-1 {
    margin-left: 1rem !important;
}
.ml-2 {
    margin-left: 2rem !important;
}
.ml-3 {
    margin-left: 3rem !important;
}
.ml-4 {
    margin-left: 4rem !important;
}
.ml-5 {
    margin-left: 5rem !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-4 {
    margin-right: 4rem !important;
}
.mr-3 {
    margin-right: 3rem !important;
}
.mr-2 {
    margin-right: 2rem !important;
}
.mr-1 {
    margin-right: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}
.mb-4 {
    margin-bottom: 4rem !important;
}
.mb-5 {
    margin-bottom: 5rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mt-3 {
    margin-top: 3rem !important;
}
.mt-4 {
    margin-top: 4rem !important;
}
.mt-5 {
    margin-top: 5rem !important;
}

nav.top-nav {
    width: 80%;
    max-height: 64px;

    ul.top-nav {
        list-style: none;
        display: flex;
        align-items: center;
        height: 25px;

        li.top-nav-items {
            margin-right: 20px;
            button.top-nav-btn {
                padding: 0;
                display: flex;
                align-items: center;
                color: #ffffff;
            }
            button.top-nav-dropdown {
                padding: 0;
                display: flex;
                align-items: center;
                color: #ffffff;
                justify-content: space-between;
            }
        }
    }
}

.desirability-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.has-applied-job {
    background-color: #e2f0d9 !important;
}

.dropdown-icons {
    color: rgba(0, 0, 0, 0.26);
}

.organization-logo {
    width: 100px;
    height: 100px;
}

.overlapping-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(243, 143, 47, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(243, 143, 47, 0.3);
}

.job-close-main-img {
    width: 30rem;
    height: 30rem;
    object-fit: cover;
}
